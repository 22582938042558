.payment-header {
    padding: 12px 16px;
    box-shadow: 0 0 10px rgba(0,0,0,0.6);
    z-index: 10;
    background-color: #ffff;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.container-static {
    padding: 4px 16px;
    background-color: #f6f6f6;
}
.defined.type_normal {
    display: block;
    font-size: 14px; 
}
.defined.type_normal dt {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: bold; 
}
.defined.type_normal dd {
    display: block;
    padding: 0 8px;
    margin-bottom: 32px; 
}
.payment-policy {
    max-width: 100%;
    height: 100%;
    padding-top: 20px;
    overflow-x: auto;
    background-color: #f6f6f6;
}
.payment-policy .content {
    object-fit: contain;
}
.payment-policy .content-mobile {
    display: none;
}
@media screen and (max-width: 576px) {
    .payment-policy .content {
        display: none;
    }
    .payment-policy .content-mobile {
        display: block;
        object-fit: contain;
    }
}